import React, {createContext, useContext, useEffect, useState} from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import getFirebase from './getFirebase';

export const Context = createContext(null);

const FirebaseProvider = ({children}) => {
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    const firebase = getFirebase(app);
    setFirebase(firebase);
  }, []);

  return <Context.Provider value={firebase}>{children}</Context.Provider>;
};

export const useFirebase = () => useContext(Context);

export default FirebaseProvider;
