import {useState} from 'react';
import {useFirebase} from './firebase';

const Signup = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <section>
      <h2>Sign in with email and password or magic link</h2>
      <label htmlFor="signin-email">
        Email
        <input
          id="signin-email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
        />
      </label>
      <button
        onClick={() =>
          firebase
            .sendSignInLinkToEmail(email)
            .then(() => alert('magic link sent'))
            .catch(alert)
        }
      >
        Send magic link
      </button>
      <br />
      <label htmlFor="signin-password">
        Password
        <input
          id="signin-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
      </label>
      <button
        onClick={() =>
          firebase
            .signInWithEmailAndPassword(email, password)
            .then(console.log)
            .catch(alert)
        }
      >
        Sign in
      </button>
    </section>
  );
};

export default Signup;
