import {useFirebase} from './firebase';

const Oauth = () => {
  const firebase = useFirebase();

  return (
    <section>
      <h2>Sign up/in with oauth</h2>
      <button
        onClick={() =>
          firebase.signinWithGoogle().then(console.log).catch(alert)
        }
      >
        Google signin
      </button>
      <br />
      <button
        onClick={() =>
          firebase.signinWithGithub().then(console.log).catch(alert)
        }
      >
        Github signin
      </button>
    </section>
  );
};

export default Oauth;
