import {useEffect} from 'react';
import {useFirebase} from './firebase';

const Profile = ({user, setUser}) => {
  const firebase = useFirebase();

  useEffect(() => {
    if (!firebase) return;

    if (firebase.isSignInWithEmailLink()) {
      firebase.signInWithEmailLink();
    }

    firebase.onAuthStateChanged(user => {
      if (user) setUser(user);
    });
  }, [firebase, setUser]);

  return user ? (
    <h3>
      Name: {user.displayName}
      <br />
      Email: {user.email} ({user.emailVerified ? 'Verified' : 'Not verified'})
      <br />
      <a href="#" onClick={() => firebase.signOut().then(() => setUser())}>
        Signout
      </a>
    </h3>
  ) : (
    'User is not logged in'
  );
};

export default Profile;
