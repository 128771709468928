import config from './config';

const makeFirebase = app => {
  app.initializeApp(config);
  const auth = app.auth();
  const googleProvider = new app.auth.GoogleAuthProvider();
  const githubProvider = new app.auth.GithubAuthProvider();

  const signinWithProvider = provider =>
    auth
      .signInWithPopup(provider)
      .then(result => {
        // const credential = result.credential;
        // const token = credential.accessToken;
        // const user = result.user;
        debugger;
      })
      .catch(alert);

  // *** Auth API ***
  return {
    onAuthStateChanged: (...args) => auth.onAuthStateChanged(...args),
    isSignInWithEmailLink: () =>
      auth.isSignInWithEmailLink(window.location.href),
    createUserWithEmailAndPassword: (email, password) =>
      auth.createUserWithEmailAndPassword(email, password),
    signInWithEmailAndPassword: (email, password) =>
      auth.signInWithEmailAndPassword(email, password),
    sendSignInLinkToEmail: email => {
      localStorage.setItem('poc-email', email);
      return auth.sendSignInLinkToEmail(email, {
        url: window.location.href,
        handleCodeInApp: true,
      });
    },
    signInWithEmailLink: () => {
      let email = window.localStorage.getItem('poc-email');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(result => {
          window.localStorage.removeItem('poc-email');
          console.log(
            'User signed in with ',
            email,
            'and is new user: ',
            result.additionalUserInfo.isNewUser,
          );
        })
        .catch(alert);
    },
    signinWithGoogle: () => signinWithProvider(googleProvider),
    signinWithGithub: () => signinWithProvider(githubProvider),
    signOut: () => auth.signOut(),
    passwordReset: email => auth.sendPasswordResetEmail(email),
    passwordUpdate: password => auth.currentUser.updatePassword(password),
    currentUser: () => auth.currentUser,
  };
};

let firebase;

export default app => {
  if (firebase) return firebase;

  firebase = makeFirebase(app);
  return firebase;
};
