import {useState} from 'react';
import {useFirebase} from './firebase';

const Signup = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <section>
      <h2>Sign up with email and password</h2>
      <label htmlFor="signup-email">
        Email
        <input
          id="signup-email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
        />
      </label>
      <br />
      <label htmlFor="signup-password">
        Password
        <input
          id="signup-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
        />
      </label>
      <button
        onClick={() =>
          firebase
            .createUserWithEmailAndPassword(email, password)
            .then(console.log)
            .catch(alert)
        }
      >
        Sign up
      </button>
    </section>
  );
};

export default Signup;
