import {useState} from 'react';
import FirebaseProvider from './firebase';
import Signup from './signup';
import Signin from './signin';
import OauthButtons from './oauth';
import Profile from './profile';

const App = () => {
  const [user, setUser] = useState();

  return (
    <div style={{width: 700, margin: '50px auto'}}>
      <FirebaseProvider>
        <header>
          Using <code>google identity platform</code> for auth.
          <Profile user={user} setUser={setUser} />
        </header>
        {!user && (
          <main>
            <Signup />
            <Signin />
            <OauthButtons />
          </main>
        )}
      </FirebaseProvider>
    </div>
  );
};

export default App;
